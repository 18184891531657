import type { XccEnvironment } from '@xcc-client/services';

// TODO: create new CNAME in so we can make the stage host the stage api
export const environment: XccEnvironment = {
  envMode: 'stage',
  isProduction: false,
  stripeApiPublicKey: 'pk_test_i5xtzD4G5sjAhR45jdq43bfm00Z8hb1Ei0',
  xccApiHost: 'https://checkout-api.ecom-stage.aarpdriversafety.org',
  xgritApiHostUrl: 'https://stg.xgritapi.com/api/1',
  xgritAuthorization: 'Basic MDY5MGI2MDc4ZjBmNTUwOTJlMmFiZDNlMmY3ODk3Zjg6',
  xgritWebAppUrl: 'https://app.aarpdriversafety.org/user/signin',
  brand: 'aarp',
  domain: '.aarpdriversafety.org',
  clientHost: 'https://www.aarpdriversafety.org/',
  termsAndConditionsUrl: 'https://www.aarp.com/terms-and-conditions/',
  recaptchaSiteKey: '6LfQdngjAAAAAI51zzbfB69TEqX60eaw2Sqi-JF2',
  stripeBetas: 'elements_enable_deferred_intent_beta_1',
  stripeApiVersion: '""',
  addPurchaseQueryParam: true,
  ambassador: {
    uid: '',
    isRecordConversionEnabled: true,
  },
  sentryDsn: '',
  passwordConfig: {
    passwordRegEx: '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{9,}$',
    passwordNotMatchMessage: 'Must be at least 9 characters with 1 uppercase letter, 1 lowercase letter, and 1 number.',
    showPasswordStrength: true,
  },
  temporaryDownurl: 'course-temporarily-unavailable/',
  adobeAnalytics: 'EN104c8b58f14043d4818bf1d2d44dc507-staging',
};
